import { PostApiResponse } from './api/post-api-response';

export interface Post {
    id: number;
    title: string;
    shortText: string;
    longText?: string;
    date: Date;
}

export function makePost(params: PostApiResponse): Post {
    return {
        id: Number(params.post_id),
        title: params.title,
        shortText: params.short_text,
        date: new Date(params.date),
    };
}
