import { useSearchParams } from 'react-router-dom';
import { useApi } from '../../router/router';
import { PostComponent } from './post';
import { OpenedPostComponent } from './opened-post';
import { useMemo } from 'react';
import { LoadingComponent } from '../loading/loading';

export function BlogComponent(): JSX.Element {
    const { isPostsLoaded, posts, pageChangeComponent } = useApi();
    const [searchParams] = useSearchParams();

    const opened = useMemo(() => {
        return Number(searchParams.get('id')) ?? undefined;
    }, [searchParams]);

    if (opened) {
        return <OpenedPostComponent id={Number(opened)} />;
    }

    if (!isPostsLoaded || !posts) {
        return <LoadingComponent />;
    }

    if (posts.length === 0) {
        return <>Nothing to see (yet)</>;
    }

    return (
        <>
            {posts.map((p) => (
                <PostComponent
                    key={p.id}
                    post={p}
                />
            ))}

            <div>{pageChangeComponent}</div>
        </>
    );
}
