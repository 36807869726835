export class Interval {
    from: number | undefined;
    to: number | undefined;

    constructor(from?: number, to?: number) {
        this.from = from;
        this.to = to;
    }

    public toString = () => {
        if (this.from && this.to && this.from !== this.to) {
            return `${this.from} - ${this.to}`;
        }

        if (this.from) {
            return `${this.from}`;
        }

        return `???`;
    };
}
