import { PhotoApiResponse } from './api/photos-api-response';

export interface Photo {
    id: number;
    url: string;
}

export function makePhoto(params: PhotoApiResponse): Photo {
    return {
        id: Number(params.picture_id),
        url: params.picture_url,
    };
}
