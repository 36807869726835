import { useEffect, useMemo, useState } from 'react';
import { MenuItem, menuItems } from '../models/menu-items';

export interface UseConfigurationApi {
    isMobile: boolean;
    getPage: (href: string) => MenuItem | undefined;
}

export function useConfiguration(): UseConfigurationApi {
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isMobile = useMemo(() => {
        return width <= 768;
    }, [width]);

    const getPage = (href: string): MenuItem | undefined => {
        return menuItems.find((m) => m.href === href);
    };

    return {
        isMobile,
        getPage,
    };
}
