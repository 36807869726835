import React, { useMemo, useState } from 'react';
import { CameraComponent } from './camera';
import { useApi } from '../../../router/router';
import { Camera } from '../../../models/camera';
import { getCamerasSortFunction } from './camera-compare';
import { useConfiguration } from '../../../hooks/use-configuration';
import clsx from 'clsx';
import { LoadingComponent } from '../../loading/loading';

const cameraSortOptions = [
    'Mount',
    'Maker',
    'Name',
    'Year from',
    // 'Picture size', 'SIZE'],
];

const filterCameraByText = (c: Camera, text: string): boolean => {
    return (
        text === '' ||
        `${c.maker} ${c.name}`.search(new RegExp(text, 'i')) !== -1
    );
};

export function CamerasComponent(): JSX.Element {
    // const filters: CameraFilters = {};
    const [sortBy, setSortBy] = useState('Maker');
    const [sortASC, setSortASC] = useState(true);
    const [filterText, setFilterText] = useState('');

    const { cameras, isLoaded } = useApi();

    const { isMobile } = useConfiguration();

    const camerasSorted = useMemo((): Camera[] | undefined => {
        if (!isLoaded || !cameras) {
            return undefined;
        }

        const sorted = [...cameras]
            .filter((c) => filterCameraByText(c, filterText))
            .sort(getCamerasSortFunction(sortBy));

        if (!sortASC) {
            sorted.reverse();
        }

        return sorted;
    }, [sortBy, sortASC, isLoaded, cameras, filterText]);

    const tableHeader = useMemo((): JSX.Element => {
        const setSort = (sortOption: string): void => {
            if (sortBy === sortOption) {
                setSortASC((old) => !old);
                return;
            }

            setSortBy(sortOption);
            setSortASC(true);
        };

        return (
            <thead>
                <tr>
                    {cameraSortOptions.map((key) => {
                        return (
                            <th
                                key={key}
                                onClick={() => setSort(key)}
                            >
                                {key}

                                {sortBy === key &&
                                    (sortASC ? <>&uarr;</> : <>&darr;</>)}
                            </th>
                        );
                    })}
                </tr>
            </thead>
        );
    }, [sortASC, sortBy]);

    if (camerasSorted === undefined) {
        return <LoadingComponent />;
    }

    return (
        <>
            <div className="list-search">
                Search:{' '}
                <input
                    type="search"
                    value={filterText}
                    onChange={(v) => setFilterText(v.target.value)}
                />
                &nbsp;<i>({camerasSorted.length})</i>
            </div>

            <table
                className={clsx(
                    'list-table',
                    isMobile ? 'list-table-mobile' : 'list-table-desktop',
                )}
            >
                {tableHeader}

                <tbody>
                    {camerasSorted.map((c) => (
                        <CameraComponent
                            key={c.id}
                            camera={c}
                        />
                    ))}
                </tbody>
            </table>
        </>
    );
}
