export enum Mount {
    ALTIX = 'ALTIX',
    CONTAX = 'CONTAX',
    EF = 'EF',
    EXAKTA = 'EXAKTA',
    FD = 'FD',
    KIEV = 'KIEV',
    L39 = 'L39',
    M42 = 'M42',
    MD = 'MD',
    NEX = 'NEX',
    OM = 'OM',
    OPEMA = 'OPEMA',
    P6 = 'P6',
    PK = 'PK',
    WERRA = 'WERRA',
    PRAKTINA = 'PRAKTINA',
    ICAREX = 'ICAREX',
}
