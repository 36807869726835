import { createBrowserRouter } from 'react-router-dom';
import { BlogComponent } from '../components/blog/blog';
import { HomeComponent } from '../components/home/home';
import { LensesComponent } from '../components/photography/lenses/lenses';
import { PhotosComponent } from '../components/photography/photos/photos';
import { MenuComponent } from '../components/menu/menu';
import { CamerasComponent } from '../components/photography/cameras/cameras';

export const routes = createBrowserRouter([
    {
        path: '/',
        element: (
            <>
                <MenuComponent />
                <HomeComponent />
            </>
        ),
    },
    {
        path: '/home',
        element: (
            <>
                <MenuComponent />
                <HomeComponent />
            </>
        ),
    },
    {
        path: '/blog',
        element: (
            <>
                <MenuComponent />
                <BlogComponent />
            </>
        ),
    },
    {
        path: '/photography/cameras',
        element: (
            <>
                <MenuComponent />
                <CamerasComponent />
            </>
        ),
    },
    {
        path: '/photography/lenses',
        element: (
            <>
                <MenuComponent />
                <LensesComponent />
            </>
        ),
    },
    {
        path: '/photography/photos',
        element: (
            <>
                <MenuComponent />
                <PhotosComponent />
            </>
        ),
    },
]);
