import { useConfiguration } from '../../hooks/use-configuration';
import { MenuMobileComponent } from './menu-mobile/menu-mobile';
import { MenuDesktopComponent } from './menu-desktop/menu-desktop';

export function MenuComponent(): JSX.Element {
    const { isMobile } = useConfiguration();

    if (isMobile) {
        return <MenuMobileComponent />;
    }

    return <MenuDesktopComponent />;
}
