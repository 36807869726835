import React from 'react';
import './page-change.css';

interface PageChangeComponentInput {
    page: number;
    pages: number;
    onPageChange: (newPage: number) => void;
}

export function PageChangeComponent({
    page,
    pages,
    onPageChange,
}: PageChangeComponentInput): JSX.Element {
    return (
        <div className="page-change">
            <div>
                <div>
                    {page - 1 >= 0 && (
                        <button onClick={() => onPageChange(page - 1)}>
                            ←
                        </button>
                    )}
                </div>

                <div>{page + 1}</div>

                <div>
                    {page + 1 < pages && (
                        <button onClick={() => onPageChange(page + 1)}>
                            →
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
