export interface MenuItem {
    title: string;
    href: string;
}

export const menuItems: MenuItem[] = [
    {
        title: 'Home',
        href: '/home',
    },
    {
        title: 'Blog',
        href: '/blog',
    },
    {
        title: 'Cameras',
        href: '/photography/cameras',
    },
    {
        title: 'Lenses',
        href: '/photography/lenses',
    },
    {
        title: 'Photos',
        href: '/photography/photos',
    },
];
