import { Mount } from '../../../enums/mount';
import { Lens } from '../../../models/lens';

const nameSortFunction = (a: Lens, b: Lens): number => {
    return a.name.localeCompare(b.name);
};

const focalLengthSortFunction = (a: Lens, b: Lens): number => {
    if (a.focalLength.from === b.focalLength.from) {
        return nameSortFunction(a, b);
    }

    return (a.focalLength.from || 0) - (b.focalLength.from || 0);
};

const apertureSortFunction = (a: Lens, b: Lens): number => {
    if (a.minAperture.from === b.minAperture.from) {
        return nameSortFunction(a, b);
    }

    return (a.minAperture.from || 0) - (b.minAperture.from || 0);
};

const mountSortFunction = (a: Lens, b: Lens): number => {
    if (a.mount === b.mount) {
        return nameSortFunction(a, b);
    }

    return Mount[a.mount ?? ''].localeCompare(Mount[b.mount] ?? '');
};

const makerSortFunction = (a: Lens, b: Lens): number => {
    if (a.maker === b.maker) {
        return nameSortFunction(a, b);
    }

    return `${a.maker} ${a.name}`.localeCompare(`${b.maker} ${b.name}`);
};

export function getLensSortFunction(
    sortOption: string,
): (a: Lens, b: Lens) => number {
    switch (sortOption) {
        case 'Name':
            return nameSortFunction;
        case 'Focal length':
            return focalLengthSortFunction;
        case 'Min. aperture':
            return apertureSortFunction;
        case 'Mount':
            return mountSortFunction;
        default:
        case 'Maker':
            return makerSortFunction;
    }
}
