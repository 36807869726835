import { useCallback } from 'react';
import { Post } from '../../models/post';
import { useNavigate } from 'react-router-dom';
import './post.css';
import clsx from 'clsx';

interface PostComponentInput {
    post: Post;
}

export function PostComponent({ post }: PostComponentInput): JSX.Element {
    const navigate = useNavigate();
    const handleClick = useCallback(() => {
        navigate(`/blog?id=${post.id}`);
    }, [post, navigate]);

    return (
        <div
            onClick={handleClick}
            className={clsx('post', 'clickable')}
        >
            <h2>{post.title}</h2>
            <h5>{post.date.toLocaleDateString()}</h5>
            <p>{post.shortText}</p>
        </div>
    );
}
