import { useEffect, useRef, useState } from 'react';
import { Album, makeAlbum } from '../models/album';
import { PhotoApiResponse } from '../models/api/photos-api-response';
import { Camera, makeCamera } from '../models/camera';
import { Lens, makeLens } from '../models/lens';
import { makePhoto, Photo } from '../models/photo';
import { CameraApiResponse } from '../models/api/camera-api-response';
import { LensApiResponse } from '../models/api/lens-api-response';
import { AlbumApiResponse } from '../models/api/album-api-response';

const albumsUrl = '/api/albums.php';
const camerasUrl = '/api/cameras.php';
const lensesUrl = '/api/lenses.php';
const photosUrl = '/api/photos.php';

export interface UseBootstrapApi {
    photos: Photo[];
    cameras: Camera[];
    lenses: Lens[];
    albums: Album[];
    isLoaded: boolean;
}

export function useBootstrap(): UseBootstrapApi {
    const [photos, setPhotos] = useState<Photo[]>([]);
    const [cameras, setCameras] = useState<Camera[]>([]);
    const [lenses, setLenses] = useState<Lens[]>([]);
    const [albums, setAlbums] = useState<Album[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const cameraToPhoto = useRef(new Map<string, Photo[]>());
    const addPhotoToCamera = (cameraId: string, photo: Photo): void => {
        if (cameraToPhoto.current.has(cameraId)) {
            cameraToPhoto.current.set(cameraId, [
                ...(cameraToPhoto.current.get(cameraId) || []),
                photo,
            ]);
            return;
        }

        cameraToPhoto.current.set(cameraId, [photo]);
    };

    const lensToPhoto = useRef(new Map<string, Photo[]>());
    const addPhotoToLens = (lensId: string, photo: Photo): void => {
        if (lensToPhoto.current.has(lensId)) {
            lensToPhoto.current.set(lensId, [
                ...(lensToPhoto.current.get(lensId) || []),
                photo,
            ]);
            return;
        }

        lensToPhoto.current.set(lensId, [photo]);
    };

    const albumsToPhoto = useRef(new Map<string, Photo[]>());
    const addPhotoToAlbums = (albumIds: string[], photo: Photo): void => {
        const addToAlbum = (albumId: string) => {
            if (albumsToPhoto.current.has(albumId)) {
                albumsToPhoto.current.set(albumId, [
                    ...(albumsToPhoto.current.get(albumId) || []),
                    photo,
                ]);
                return;
            }

            albumsToPhoto.current.set(albumId, [photo]);
        };
        albumIds.forEach(addToAlbum);
    };

    useEffect(() => {
        const fetchPictures = async () => {
            await fetch(photosUrl)
                .then((response) => response.json())
                .then((data) => {
                    setPhotos(
                        (data as unknown as PhotoApiResponse[]).map((p) => {
                            const photo = makePhoto(p);
                            p.camera && addPhotoToCamera(p.camera, photo);
                            p.lens && addPhotoToLens(p.lens, photo);
                            p.album_ids &&
                                addPhotoToAlbums(p.album_ids.split(','), photo);
                            return photo;
                        }),
                    );
                });
        };
        fetchPictures();

        const fetchCameras = async () => {
            await fetch(camerasUrl)
                .then((response) => response.json())
                .then((data) => {
                    setCameras(
                        (data as unknown as CameraApiResponse[]).map((r) => {
                            return {
                                ...makeCamera(r),
                                photos:
                                    cameraToPhoto.current.get(r.camera_id) ||
                                    [],
                            };
                        }),
                    );
                });
        };
        fetchCameras();

        const fetchLenses = async () => {
            await fetch(lensesUrl)
                .then((response) => response.json())
                .then((data) => {
                    setLenses(
                        (data as unknown as LensApiResponse[]).map((r) => {
                            return {
                                ...makeLens(r),
                                photos:
                                    lensToPhoto.current.get(r.lens_id) || [],
                            };
                        }),
                    );
                });
        };
        fetchLenses();

        const fetchAlbums = async () => {
            await fetch(albumsUrl)
                .then((response) => response.json())
                .then((data) => {
                    setAlbums(
                        (data as unknown as AlbumApiResponse[]).map((r) => {
                            return {
                                ...makeAlbum(r),
                                photos:
                                    albumsToPhoto.current.get(r.album_id) || [],
                            };
                        }),
                    );
                });
        };
        fetchAlbums();

        setIsLoaded(true);
    }, []);

    return {
        photos,
        cameras,
        lenses,
        albums,
        isLoaded,
    };
}
