import React from 'react';
import { menuItems } from '../../../models/menu-items';
import { MenuItemComponent } from '../menu-item/menu-item';

export function MenuDesktopComponent(): JSX.Element {
    return (
        <div className="menu">
            {menuItems.map((menuItem) => (
                <MenuItemComponent
                    key={menuItem.title}
                    menuItem={menuItem}
                />
            ))}
        </div>
    );
}
