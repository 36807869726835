import { Interval } from '../../models/interval';
import './footer.css';

export function FooterComponent() {
    const copyrightTime = new Interval(2024, new Date().getFullYear());
    return (
        <div className="footer">
            {`@ ${copyrightTime.toString()} Gabor Farkas`}
        </div>
    );
}
