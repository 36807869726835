import { Camera } from '../../../models/camera';
import { MountComponent } from '../mount/mount';

interface CameraComponentProps {
    camera: Camera;
}

export function CameraComponent({ camera }: CameraComponentProps): JSX.Element {
    return (
        <tr>
            <td>
                <MountComponent mount={camera.mount} />
            </td>

            <td>
                <i>{camera.maker}</i>
            </td>

            <td>{camera.name}</td>

            <td>({camera.year.toString()})</td>
        </tr>
    );
}
