import { AlbumApiResponse } from './api/album-api-response';
import { Photo } from './photo';

export interface Album {
    id: number;
    title: string;
    description: string;
    photos: Photo[];
}

export function makeAlbum(params: AlbumApiResponse): Album {
    return {
        id: Number(params.album_id),
        title: params.title,
        description: params.description,
        photos: [],
    };
}
