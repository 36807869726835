import React from 'react';
import './home.css';
import clsx from 'clsx';
import { useConfiguration } from '../../hooks/use-configuration';

export function HomeComponent(): JSX.Element {
    const { isMobile } = useConfiguration();

    return (
        <div className="home">
            <img
                src="/images/en.jpg"
                alt="Gabor Farkas"
                className={clsx('home-img', { 'home-img-mobile': isMobile })}
            />

            <div className={clsx('home-text')}>
                <p>
                    Hi! My name is <strong>Gábor Farkas</strong>, professional
                    developer and amateur photographer.
                </p>

                <p>
                    I created this page mainly to unload on the many,{' '}
                    <strong>many</strong> intersting things I discover as a
                    photographer, and to document some of my obscure and not so
                    obscure cameras that I have found here and there.
                </p>

                <p>
                    I hope you'll find something useful or at least mildly
                    amusing here!
                </p>

                <p className="home-text__instagram-paragraph">
                    <a href="https://www.instagram.com/that_shutter_click/">
                        @that_shutter_click
                    </a>
                </p>
            </div>
        </div>
    );
}
