import { CameraType } from '../enums/camera-type';
import { Mount } from '../enums/mount';
import { CameraApiResponse } from './api/camera-api-response';
import { Interval } from './interval';
import { Photo } from './photo';

export interface Camera {
    id: number;
    maker: string;
    name: string;
    picUrl: string;
    resolution?: number | undefined;
    size: CameraType;
    mount: Mount;
    year: Interval;
    description?: string;
    date: Date;
    photos: Photo[];
}

export function makeCamera(params: CameraApiResponse): Camera {
    return {
        id: Number(params.camera_id),
        maker: params.maker,
        name: params.name,
        picUrl: params.pic_url,
        size: params.size as unknown as CameraType,
        mount: params.mount as unknown as Mount,
        year: new Interval(Number(params.year_from), Number(params.year_to)),
        date: new Date(params.date),
        photos: [],
    };
}
