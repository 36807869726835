import { useNavigate } from 'react-router-dom';
import { MenuItem } from '../../../models/menu-items';
import './menu-item.css';
import { useConfiguration } from '../../../hooks/use-configuration';
import clsx from 'clsx';

interface MenuItemInput {
    menuItem: MenuItem;
}

export function MenuItemComponent({ menuItem }: MenuItemInput): JSX.Element {
    const navigate = useNavigate();
    const { isMobile } = useConfiguration();

    const handleClick = () => {
        navigate(menuItem.href);
    };

    return (
        <button
            className={clsx('menu-button', 'clickable', {
                'menu-button-desktop': !isMobile,
            })}
            type="button"
            onClick={handleClick}
        >
            {menuItem.title}
        </button>
    );
}
