import { Mount } from '../../../enums/mount';
import { Camera } from '../../../models/camera';

const nameSortFunction = (a: Camera, b: Camera): number => {
    return a.name.localeCompare(b.name);
};

const mountSortFunction = (a: Camera, b: Camera): number => {
    if (a.mount === b.mount) {
        return nameSortFunction(a, b);
    }

    return (Mount[a.mount] ?? '').localeCompare(Mount[b.mount] ?? '');
};

const yearSortFunction = (a: Camera, b: Camera): number => {
    if (a.year.from === b.year.from) {
        return nameSortFunction(a, b);
    }

    return (a.year.from || 0) - (b.year.from || 0);
};

const makerSortFunction = (a: Camera, b: Camera): number => {
    if (a.maker === b.maker) {
        return nameSortFunction(a, b);
    }

    return a.maker.localeCompare(b.maker);
};

export function getCamerasSortFunction(
    sortOption: string,
): (a: Camera, b: Camera) => number {
    switch (sortOption) {
        case 'Name':
            return nameSortFunction;
        case 'Mount':
            return mountSortFunction;
        case 'Year from':
            return yearSortFunction;
        // case 'SIZE':
        // TODO: enums need to be converted to a better solution
        //   const sizeSortFunction = (a: Camera, b: Camera): number => {
        //     console.log(CameraType[a.size]);
        //     return -1;
        //   }
        //   return sizeSortFunction
        default:
        case 'Maker':
            return makerSortFunction;
    }
}
