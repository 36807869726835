import { useCallback, useEffect, useMemo, useState } from 'react';
import { makePost, Post } from '../models/post';
import {
    PostApiResponse,
    PostApiResponseWithInit,
} from '../models/api/post-api-response';
import { PageChangeComponent } from '../components/blog/page-change-component/page-change';

const blogPostUrl = '/api/posts.php';

export interface UseBlogApi {
    isPostsLoaded: boolean;
    page: number;
    posts: Post[];
    pageChangeComponent: JSX.Element;
    getPost: (id: number, setPost: (post: Post) => void) => void;
}

export function useBlog(): UseBlogApi {
    const [initialized, setInitialized] = useState<boolean>(false);
    const [isPostsLoaded, setIsPostsLoaded] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [allPosts, setAllPosts] = useState(
        new Map<number, Post[] | undefined>(),
    );

    useEffect(() => {
        if (allPosts.get(page) !== undefined) {
            return;
        }

        setIsPostsLoaded(false);

        if (!initialized) {
            const fetchPostsWithInit = async () => {
                await fetch(`${blogPostUrl}?offset=0&init=true`)
                    .then((response) => response.json())
                    .then((data) => {
                        const posts = new Map<number, Post[] | undefined>();
                        const pages = Math.ceil(
                            Number(
                                (data as unknown as PostApiResponseWithInit)
                                    .pages,
                            ) / 10,
                        );
                        for (let i = 0; i < pages; i++) {
                            posts.set(i, undefined);
                        }

                        const firstPage = (
                            data as unknown as PostApiResponseWithInit
                        ).posts;
                        posts.set(
                            page,
                            firstPage.map((p) => makePost(p)),
                        );

                        setAllPosts(posts);
                        setInitialized(true);
                    });
            };
            fetchPostsWithInit();
            return;
        }

        const fetchPosts = async () => {
            await fetch(`${blogPostUrl}?offset=${page * 10}`)
                .then((response) => response.json())
                .then((data) => {
                    setAllPosts((old) => {
                        if (old.get(page) !== undefined) {
                            return old;
                        }

                        const newPosts = new Map<number, Post[] | undefined>(
                            old,
                        );
                        newPosts.set(
                            page,
                            (data as unknown as PostApiResponse[]).map((p) =>
                                makePost(p),
                            ),
                        );
                        return newPosts;
                    });
                });
        };
        fetchPosts();
    }, [page, allPosts, setAllPosts, initialized]);

    const shownPosts = useMemo((): Post[] => {
        if (allPosts.has(page)) {
            setIsPostsLoaded(true);
            return allPosts.get(page) || [];
        }

        setIsPostsLoaded(true);
        return [];
    }, [allPosts, page]);

    const onPageChange = useCallback((newPage: number) => {
        setPage((old) => {
            if (old === newPage) {
                return old;
            }

            return newPage;
        });
    }, []);

    const pageChangeComponent = useMemo(() => {
        return (
            <PageChangeComponent
                page={page}
                pages={allPosts.size}
                onPageChange={onPageChange}
            />
        );
    }, [page, allPosts, onPageChange]);

    const getPost = useCallback(
        (id: number, setPost: (post: Post) => void): void => {
            const fetchPost = async () => {
                await fetch(`${blogPostUrl}?id=${id}`)
                    .then((response) => response.json())
                    .then((data) => {
                        setPost(makePost(data[0]));
                    });
            };
            fetchPost();
        },
        [],
    );

    return {
        isPostsLoaded,
        page,
        posts: shownPosts,
        pageChangeComponent,
        getPost,
    };
}
