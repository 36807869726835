import { Mount } from '../enums/mount';
import { Interval } from './interval';
import { LensApiResponse } from './api/lens-api-response';
import { Photo } from './photo';

export interface Lens {
    id: number;
    maker: string;
    name: string;
    picUrl: string;
    focalLength: Interval;
    minAperture: Interval;
    maxAperture?: number;
    mount: Mount;
    isManual: boolean;
    description?: string;
    date: Date;
    photos: Photo[];
}

export function makeLens(params: LensApiResponse): Lens {
    return {
        id: Number(params.lens_id),
        maker: params.maker,
        name: params.name,
        picUrl: params.pic_url,
        mount: params.mount as unknown as Mount,
        focalLength: new Interval(
            Number(params.min_focal_length),
            Number(params.max_focal_length),
        ),
        minAperture: new Interval(
            Number(params.min_aperture),
            Number(params.min_aperture_2),
        ),
        maxAperture: Number(params.max_aperture),
        isManual: params.is_manual === '1',
        date: new Date(params.date),
        photos: [],
    };
}
