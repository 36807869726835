import { LensComponent } from './lens';
import { useApi } from '../../../router/router';
import { useMemo, useState } from 'react';
import { Lens } from '../../../models/lens';
import { getLensSortFunction } from './lens-compare';
import { useConfiguration } from '../../../hooks/use-configuration';
import clsx from 'clsx';
import { LoadingComponent } from '../../loading/loading';

const lensSortOptions = [
    'Mount',
    'Maker',
    'Name',
    'Focal length',
    'Min. aperture',
];

const filterLensesByText = (l: Lens, text: string): boolean => {
    return (
        text === '' ||
        `${l.maker} ${l.name}`.search(new RegExp(text, 'i')) !== -1
    );
};

export function LensesComponent(): JSX.Element {
    // const filters: LensFilters = {};
    const [sortBy, setSortBy] = useState('Maker');
    const [sortASC, setSortASC] = useState(true);
    const [filterText, setFilterText] = useState('');

    const { lenses, isLoaded } = useApi();

    const { isMobile } = useConfiguration();

    const lensesSorted = useMemo((): Lens[] | undefined => {
        if (!isLoaded || !lenses) {
            return undefined;
        }

        const sorted = [...lenses]
            .filter((l) => filterLensesByText(l, filterText))
            .sort(getLensSortFunction(sortBy));

        if (!sortASC) {
            sorted.reverse();
        }

        return sorted;
    }, [sortBy, sortASC, isLoaded, lenses, filterText]);

    const tableHeader = useMemo((): JSX.Element => {
        const setSort = (sortOption: string): void => {
            if (sortBy === sortOption) {
                setSortASC((old) => !old);
                return;
            }

            setSortBy(sortOption);
            setSortASC(true);
        };

        return (
            <thead>
                <tr>
                    {lensSortOptions.map((key) => {
                        return (
                            <th
                                key={key}
                                onClick={() => setSort(key)}
                            >
                                {key}

                                {sortBy === key &&
                                    (sortASC ? <>&uarr;</> : <>&darr;</>)}
                            </th>
                        );
                    })}
                </tr>
            </thead>
        );
    }, [sortASC, sortBy]);

    if (lensesSorted === undefined) {
        return <LoadingComponent />;
    }

    return (
        <>
            <div className="list-search">
                Search:{' '}
                <input
                    type="search"
                    value={filterText}
                    onChange={(v) => setFilterText(v.target.value)}
                />
                &nbsp;<i>({lensesSorted.length})</i>
            </div>

            <table
                className={clsx(
                    'list-table',
                    isMobile ? 'list-table-mobile' : 'list-table-desktop',
                )}
            >
                {tableHeader}

                <tbody>
                    {lensesSorted.map((l) => (
                        <LensComponent
                            key={l.id}
                            lens={l}
                        />
                    ))}
                </tbody>
            </table>
        </>
    );
}
